import React, { useState, useEffect } from 'react';

import GlobalStyles from '../components/Page/GlobalStyles';
import Header from '../components/Header';
import MainMenu from '../components/MainMenu';

import Cursor from '../cursor/cursor';
import '../cursor/index.css';

const Layout = ({ children, path }) => {
  const [openMenu, setOpenMenu] = useState(false);
  
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const cursor = new Cursor(document.querySelector('.cursor'));
  
      [...document.querySelectorAll('a, button')].forEach((el) => {
        el.addEventListener('mouseenter', () => cursor.emit('enter'));
        el.addEventListener('mouseleave', () => cursor.emit('leave'));
      });
    }
  }, [openMenu, path])

  return (
    <>
      <svg className="cursor" width="140" height="140" viewBox="0 0 140 140">
        <defs>
          <filter
            id="filter-1"
            x="-50%"
            y="-50%"
            width="200%"
            height="200%"
            filterUnits="objectBoundingBox"
          >
            <feTurbulence
              type="fractalNoise"
              baseFrequency="20"
              numOctaves="10"
              result="warp"
            />
            <feDisplacementMap
              xChannelSelector="R"
              yChannelSelector="G"
              scale="60"
              in="SourceGraphic"
              in2="warp"
            />
          </filter>
        </defs>
        <circle class="cursor__inner" cx="70" cy="70" r="40" />
      </svg>

      <Header setOpenMenu={setOpenMenu} open={openMenu} />

      <MainMenu open={openMenu} />

      <GlobalStyles />

      {children}
    </>
  );
};

export default Layout;
