import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  @import "@fontsource/montserrat/index.css";
  @import "@fontsource/montserrat/100.css";
  @import "@fontsource/montserrat/200.css";
  @import "@fontsource/montserrat/300.css";
  @import "@fontsource/montserrat/400.css";
  @import "@fontsource/montserrat/500.css";
  @import "@fontsource/montserrat/600.css";
  @import "@fontsource/montserrat/700.css";
  @import "@fontsource/montserrat/800.css";
  @import "@fontsource/montserrat/900.css";
  
  @import "@fontsource/krona-one/index.css";
  @import "@fontsource/krona-one/400.css";
  @import url('https://fonts.googleapis.com/css2?family=Krona+One&display=swap');

  *, *:after, *:before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: white;
    scroll-behavior: smooth;
  }

  #viewport {
    max-height: 100vh;
  }

  html {
    overflow-x: hidden;
  }

  body {
    overflow-x: hidden;
    font-family: 'Montserrat';
    font-weight: 400;
    background: #161013;
    font-size: 14px;
    transition: all 800ms linear; 
  }

  *:active, *:focus {
    outline: none;
  }
`;
