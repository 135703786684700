import { AnimatePresence, motion } from "framer-motion";
import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import media from "styled-media-query";

import RevealWord from "../RevealWord";

const Menu = styled(motion.div)`
  width: 100%;
  height: 100vh;
  background: #2c2629;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
`;

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;

  ${media.lessThan("medium")`
    align-items: flex-end;
    height: 100vh;
  `}
`;

const BlockMenu = styled.div`
  width: 60%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;

  ${media.lessThan("medium")`
    width: 100%;
    height: auto;
    margin-top: 60px;
  `}
`;

const Block = styled.div`
  ${media.lessThan("medium")`
    width: 100%;
    padding: 16px;
  `}
`;

const MenuItem = styled(motion.div)`
  font-family: "Krona One" !important;
  font-size: 52px;
  margin: 10vh 0;
  display: flex;
  flex-flow: row wrap;

  a {
    text-decoration: none;
    transition: all 600ms ease;
  }

  :hover div {
    color: white !important;
  }

  :hover a {
    color: #74686e;
  }

  ${media.lessThan("medium")`
    font-size: 20px;
    line-height: 62px;
    margin: 0;
  `}
`;

const BlockAddress = styled.div`
  width: 40%;
  height: 100vh;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  position: relative;

  ${media.lessThan("medium")`
    width: 100%;
    height: auto;

    border-top: 1px solid rgba(255, 255, 255, 0.11);
  `}

  p {
    max-width: 340px;
    line-height: 24px;

    ${media.lessThan("medium")`
      font-size: 12px;
      line-height: 24px;
    `}

    :not(:last-of-type) {
      margin-bottom: 64px;

      ${media.lessThan("medium")`
        margin-bottom: 12px;
      `}
    }
  }
`;

const Medias = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-top: 100px;
  margin-bottom: 24px;

  ${media.lessThan("medium")`
    margin-top: 32px;
  `}
`;

const LinkTo = styled.a`
  font-size: 10px;
  color: #7b7b7b;
  text-decoration: none;
  transition: all 800ms ease;

  :hover {
    color: white;
  }
`;

const Line = styled(motion.div)`
  width: 1px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  background: rgba(255, 255, 255, 0.11);

  ${media.lessThan("medium")`
    display: none;
  `}
`;

const Index = styled.div`
  transform-origin: 50%;
  transform: rotate(-90deg);
  margin-right: 32px;
  color: #74686e;
  font-size: 16px;
  font-weight: 600;
  transition: color 600ms ease;

  div {
    font-family: "Montserrat";
    color: #74686e;
    transition: color 600ms ease;
  }
`;

const MainMenu = ({ open }) => {
  return (
    <AnimatePresence>
      {open && (
        <Menu
          initial={{ opacity: 0, visibility: "hidden" }}
          animate={{ opacity: 1, visibility: "visible" }}
          exit={{
            opacity: 0,
            visibility: "hidden",
            transition: { delay: 0.8 },
          }}
        >
          <Container>
            <BlockMenu>
              <Block>
                <MenuItem
                  initial={{ opacity: 0, y: 20 }}
                  animate={{
                    opacity: 1,
                    y: 0,
                    transition: { type: "spring", bounce: 0, delay: 0.2 },
                  }}
                  exit={{
                    opacity: 0,
                    y: -20,
                    transition: { delay: 0, bounce: 0.1 },
                  }}
                >
                  <Index>
                    <RevealWord delay={0.2}>00</RevealWord>
                  </Index>
                  <Link to="/">HOME</Link>
                </MenuItem>

                <MenuItem
                  initial={{ opacity: 0, y: 20 }}
                  animate={{
                    opacity: 1,
                    y: 0,
                    transition: { type: "spring", bounce: 0, delay: 0.25 },
                  }}
                  exit={{
                    opacity: 0,
                    y: -20,
                    transition: { delay: 0.05, bounce: 0.1 },
                  }}
                >
                  <Index>
                    <RevealWord delay={0.25}>01</RevealWord>
                  </Index>
                  <Link to="/sobre-o-lab721/">SOBRE O LAB721</Link>
                </MenuItem>

                <MenuItem
                  initial={{ opacity: 0, y: 20 }}
                  animate={{
                    opacity: 1,
                    y: 0,
                    transition: { type: "spring", bounce: 0, delay: 0.3 },
                  }}
                  exit={{
                    opacity: 0,
                    y: -20,
                    transition: { delay: 0.1, bounce: 0.1 },
                  }}
                >
                  <Index>
                    <RevealWord delay={0.3}>02</RevealWord>
                  </Index>
                  <Link to="/cases/">CASES</Link>
                </MenuItem>

                <MenuItem
                  initial={{ opacity: 0, y: 20 }}
                  animate={{
                    opacity: 1,
                    y: 0,
                    transition: { type: "spring", bounce: 0, delay: 0.35 },
                  }}
                  exit={{
                    opacity: 0,
                    y: -20,
                    transition: { delay: 0.15, bounce: 0.1 },
                  }}
                >
                  <Index>
                    <RevealWord delay={0.35}>03</RevealWord>
                  </Index>
                  <Link to="/blog/">BLOG</Link>
                </MenuItem>

                <MenuItem
                  initial={{ opacity: 0, y: 20 }}
                  animate={{
                    opacity: 1,
                    y: 0,
                    transition: { type: "spring", bounce: 0, delay: 0.4 },
                  }}
                  exit={{
                    opacity: 0,
                    y: -20,
                    transition: { delay: 0.2, bounce: 0.1 },
                  }}
                >
                  <Index>
                    <RevealWord delay={0.4}>04</RevealWord>
                  </Index>
                  <Link to="/contato/">CONTATO</Link>
                </MenuItem>
              </Block>
            </BlockMenu>

            <BlockAddress>
              <Line
                initial={{ height: 0 }}
                animate={{
                  height: "100vh",
                  transition: { delay: 0.1, duration: 1, bounce: 0 },
                }}
                exit={{ height: 0, transition: { duration: 0.5, bounce: 0 } }}
              />

              <Block>
                <motion.p
                  initial={{ opacity: 0, y: 10 }}
                  animate={{
                    opacity: 1,
                    y: 0,
                    transition: { bounce: 0, delay: 0.4 },
                  }}
                  exit={{ opacity: 0 }}
                >
                  Av. Itália, 277, sala 805 - São Pelegrino, Caxias do Sul - RS
                </motion.p>
                <motion.p
                  initial={{ opacity: 0, y: 10 }}
                  animate={{
                    opacity: 1,
                    y: 0,
                    transition: { bounce: 0, delay: 0.5 },
                  }}
                  exit={{ opacity: 0 }}
                >
                  falecom@lab721.com.br
                  <br />
                  +55 54 9.8139 9232
                </motion.p>

                <Medias
                  initial={{ opacity: 0, y: 10 }}
                  animate={{
                    opacity: 1,
                    y: 0,
                    transition: { bounce: 0, delay: 0.5 },
                  }}
                  exit={{ opacity: 0 }}
                >
                  <LinkTo
                    rel="noopener noreferer"
                    target="_blank"
                    href="https://www.facebook.com/lab721"
                    title="Lab721 no Facebook"
                  >
                    FACEBOOK
                  </LinkTo>
                  <LinkTo
                    rel="noopener noreferer"
                    target="_blank"
                    href="https://www.instagram.com/ladigital_co/"
                    title="Lab721 no Instagram"
                  >
                    INSTAGRAM
                  </LinkTo>
                  {/* <LinkTo rel="noopener noreferer" target="_blank" href="#">
                    LINKEDIN
                  </LinkTo> */}
                </Medias>
              </Block>
            </BlockAddress>
          </Container>
        </Menu>
      )}
    </AnimatePresence>
  );
};

export default MainMenu;
