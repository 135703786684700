import { globalHistory } from "@reach/router";
import { motion } from "framer-motion";
import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import RevealWord from "../RevealWord";


const Wrapper = styled.header`
  width: calc(100% - 64px);
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  position: fixed;
  top: 35px;
  left: 0;
  z-index: 9;
  right: 0%;
  margin: auto;
  align-items: center;
  z-index: 999;

  ${media.lessThan("medium")`
    width: calc(100% - 34px);
    top: 17px;

    svg {
      width: 60px;
    }
  `}
`;

const Medias = styled.div`
  width: 70vh;
  height: 10px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 60px;
  position: fixed;
  top: 65%;
  bottom: 0;
  margin: auto;
  z-index: 9;
  right: 55px;
  transform: rotate(90deg);
  transform-origin: 100%;

  ${media.lessThan("medium")`
    display: none;
  `}

  a {
    font-size: 10px;
    letter-spacing: 1px;
    color: rgba(255, 255, 255, 0.2);
    text-transform: uppercase;
    text-decoration: none;
    user-select: none;
    transition: all 800ms ease;

    :hover {
      color: white;
    }
  }
`;

const MenuToggle = styled(motion.button)`
  width: 40px;
  height: 40px;
  margin-left: auto;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  background: none;
  border: 0;
  outline: none;
`;

const Line = styled(motion.div)`
  width: 100%;
  height: 1px;
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0%;
  margin: auto;
  transform-origin: 50%;

  :nth-child(1) {
    top: -20px;
  }

  :nth-child(3) {
    top: 20px;
  }

  :nth-child(4) {
    width: 0;
    transform: rotate(45deg);
  }

  :nth-child(5) {
    width: 0;
    transform: rotate(-45deg);
  }
`;

const spring = {
  type: "spring",
  damping: 15,
  stiffness: 50,
};

const Header = ({ setOpenMenu, open }) => {
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") setOpenMenu(false);
    });
  }, [setOpenMenu]);

  return (
    <>
      <Wrapper>
        <Link to="/">
          {/* <Logo /> */}
          <svg width="122" height="21.515" viewBox="0 0 122 21.515">
            <defs>
              <clipPath id="clip-path">
                <rect id="Retângulo_1" data-name="Retângulo 1" width="122" height="21.515" fill="#fff"/>
              </clipPath>
            </defs>
            <g id="Grupo_3" data-name="Grupo 3" transform="translate(0 0)">
              <path id="Caminho_1" data-name="Caminho 1" d="M0,.363V21.515H15.176V16.936H4.57V.363Z" transform="translate(0 0)" fill="#fff"/>
              <g id="Grupo_2" data-name="Grupo 2" transform="translate(0 0)">
                <g id="Grupo_1" data-name="Grupo 1" clip-path="url(#clip-path)">
                  <path id="Caminho_2" data-name="Caminho 2" d="M62.7,10.515a5.085,5.085,0,0,0,2.81-4.653,4.688,4.688,0,0,0-2.07-4.019A10.124,10.124,0,0,0,57.5.363H47.469V21.514h10.64a11.042,11.042,0,0,0,6.3-1.495,4.889,4.889,0,0,0,2.161-4.277,5.286,5.286,0,0,0-1.027-3.308,5.593,5.593,0,0,0-2.84-1.919M52.034,4.049H56.9a5.009,5.009,0,0,1,2.75.62,2.078,2.078,0,0,1,.936,1.859A2.118,2.118,0,0,1,59.649,8.4a4.914,4.914,0,0,1-2.75.635H52.034Zm8.627,13.145a5.42,5.42,0,0,1-2.916.635H52.034V12.6h5.712q3.9,0,3.9,2.629a2.175,2.175,0,0,1-.981,1.964" transform="translate(-0.037 0)" fill="#fff"/>
                  <path id="Caminho_3" data-name="Caminho 3" d="M104.384,12.933a12.437,12.437,0,0,0,2.811-3.445,7.385,7.385,0,0,0,.724-3.233,5.631,5.631,0,0,0-.982-3.308A6.358,6.358,0,0,0,104.188.77,10.258,10.258,0,0,0,100.062,0a11.4,11.4,0,0,0-4.985,1.042,8.369,8.369,0,0,0-3.415,2.886l3.566,2.3A4.807,4.807,0,0,1,97.1,4.638a5.787,5.787,0,0,1,2.508-.53,4.005,4.005,0,0,1,2.523.68,2.335,2.335,0,0,1,.86,1.948,3.863,3.863,0,0,1-.453,1.768,8.68,8.68,0,0,1-1.753,2.131l-8.159,7.7v3.173h15.984V17.526H99.519Z" transform="translate(-0.072 0)" fill="#fff"/>
                  <path id="Caminho_4" data-name="Caminho 4" d="M112.963.363V4.942h4.547V21.515h4.578V.363Z" transform="translate(-0.089 0)" fill="#fff"/>
                  <path id="Caminho_5" data-name="Caminho 5" d="M28.567.363,19.139,21.515h5.016L31,6.227l6.723,15.288H42.86L33.4.363Z" transform="translate(-0.015 0)" fill="#fff"/>
                  <path id="Caminho_6" data-name="Caminho 6" d="M71.834,7.8h4.585V4.942h6.515L75.611,21.515h4.932l8.32-18.562V.362H71.834Z" transform="translate(-0.056 0)" fill="#fff"/>
                </g>
              </g>
            </g>
          </svg>
        </Link>
        <MenuToggle
          onClick={() => setOpenMenu((open) => !open)}
          onHoverStart={() => setHovered(true)}
          onHoverEnd={() => setHovered(false)}
        >
          <Line
            initial={{ x: -100 }}
            animate={{
              x: open ? 100 : hovered ? 10 : 0,
              transition: { ...spring, delay: !hovered ? 0.5 : 0 },
            }}
          />
          <Line
            initial={{ x: 100 }}
            animate={{
              x: open ? -100 : hovered ? -10 : 0,
              transition: { ...spring, delay: !hovered ? 0.1 : 0 },
            }}
          />
          <Line
            initial={{ x: -100 }}
            animate={{
              x: open ? 100 : hovered ? 10 : 0,
              transition: { ...spring, delay: !hovered ? 0.2 : 0 },
            }}
          />

          <Line
            initial={{ opacity: 1 }}
            animate={{
              width: open ? "100%" : 0,
              transition: { bounce: 0.1, delay: open ? 0.4 : 0.1 },
            }}
          />
          <Line
            initial={{ opacity: 1 }}
            animate={{
              width: open ? "100%" : 0,
              transition: { bounce: 0.1, delay: open ? 0.5 : 0 },
            }}
          />
        </MenuToggle>
      </Wrapper>
      <Medias>
        <RevealWord delay={1.3}>
          <a
            href="https://www.facebook.com/lab721"
            rel="noopener noreferer"
            target="_blank"
            title="Lab721 no Facebook"
          >
            Facebook
          </a>
        </RevealWord>
        <RevealWord delay={1.4}>
          <a
            href="https://www.instagram.com/ladigital_co/"
            rel="noopener noreferer"
            target="_blank"
            title="Lab721 no Instagram"
          >
            Instagram
          </a>
        </RevealWord>
        {/* <RevealWord delay={1.5}>
          <a href="#" title="Lab721 no Linkedin">
            Linkedin
          </a>
        </RevealWord> */}
      </Medias>
    </>
  );
};

export default Header;
