import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const RevealMask = styled(motion.div)`
  overflow: hidden;
  display: flex;
  flex-flow: row wrap;
`;

const RevealWord = ({ children, delay = 0, exitDelay = 0 }) => (
  <RevealMask>
    <motion.div
      initial={{ y: 120 }}
      animate={{
        y: 0,
        transition: { type: 'spring', bounce: 0.1, delay: 0.2 + delay },
      }}
      exit={{
        y: -120,
        transition: { type: 'spring', bounce: 0.1, delay: 0.2 + exitDelay },
      }}
    >
      {children}
    </motion.div>
  </RevealMask>
);

export default RevealWord;
